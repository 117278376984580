require('../css/font.css');
require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

async function run(e) {

    document.body.addEventListener('click', e => {

        if (e.target.classList.contains('scroll-to-link')) {
            scrollTo(e);
        } else if (e.target.classList.contains('calendar-btn')) {

            document.querySelectorAll('.calendar-btn')
                .forEach(btn => btn === e.target ? btn.classList.add('active') : btn.classList.remove('active'));

            Array.from(document.querySelectorAll('.calendar-content > div')).forEach(x => {
                x.classList.contains(e.target.textContent.toLowerCase())
                    ? x.classList.add('active')
                    : x.classList.remove('active')
            });
        } else if (e.target.classList.contains('calendar-btn-mobile')) {

            document.querySelectorAll('.calendar-btn-mobile')
                .forEach(btn => btn === e.target ? btn.classList.add('active') : btn.classList.remove('active'));

            Array.from(document.querySelectorAll('.calendar-content > div')).forEach(x => {
                if (x === e.target.nextElementSibling) {
                    const measure = x.querySelector('.date');
                    x.style.height = measure.clientHeight + 'px';
                    x.classList.add('active');
                }  else {
                    x.style.height = 0 + 'px';
                    x.classList.remove('active');
                }
            });
        }
    });
}


function scrollTo(e) {

    e.preventDefault();
    const link = e.target;
    const id = link.href.slice(link.href.lastIndexOf('#') + 1);
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });

    if (link.dataset.tab) {
        document.getElementById(link.dataset.tab).click();
    }
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}